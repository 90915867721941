// hostnames
export const HOSTNAME = 'https://www.easydata.co.za'; // Production

// frequency multipliers
export const MULTIPLIERS = {
  M: 12,
  Q: 4,
  A: 1,
};

// colors
export const GREY = '#9197a3';
export const GREY_LIGHT = '#eeeeee';

// export const BLUE = '#004ecb';
export const BLUE = '#2176ff';
export const BLUE_LIGHT = '#ddeeff';

export const GREEN = '#0aba74';
export const GREEN_LIGHT = '#d7fdee';

export const RED = '#ff3e3e';
export const RED_LIGHT = '#ffd7d7';

export const MONTHLY = '#fa7600';
export const MONTHLY_LIGHT = '#ffe1c7';
export const QUARTERLY = '#48a3e0';
export const QUARTERLY_LIGHT = '#cbe5f6';
export const ANNUAL = '#8d1f20';
export const ANNUAL_LIGHT = '#f6d7d7';
