import { combineReducers } from 'redux';

import app from './state/app';
import selections from './state/selections';

const rootReducer = combineReducers({
  app: app.reducer,
  selections: selections.reducer,
});

export default rootReducer;
