import { reducer } from 'redux-scaffolding';

const { initialState, elems } = reducer;

const nameSpace = 'selections';

const selections = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return elems(nameSpace, state, action);
  }
};

export default selections;
