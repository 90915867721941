import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HOSTNAME } from '../constants';

import useFormInput from '../hooks/useFormInput';

import stateApp from '../state/app';

const { updateHostname } = stateApp.actions;

const HostnameForm = () => {
  const dispatch = useDispatch();

  // app
  const hostname = useSelector((state) => state.app.hostname || HOSTNAME);

  const newHostname = useFormInput(hostname);

  const [hostnameMsg, setHostnameMsg] = useState('');

  const hostnameError = '';

  function handleSetHostname(e) {
    e.preventDefault();
    // TODO: check if valid hostname
    dispatch(updateHostname(newHostname.value));
    setHostnameMsg('Success');
  }

  /* eslint-disable */
  return (
    <div>
      <form onSubmit={handleSetHostname}>
        <div className="bs4 mb-3">
          <label htmlFor="easyDataHostname">
            Website Address
          </label>
          <input
            id="easyDataHostname"
            className="bs4 form-control"
            type="text"
            placeholder="Web address"
            value={newHostname.value}
            onChange={newHostname.onChange}
            aria-describedby="easyDataHostnameFeedback"
            required
            autoComplete="off"
            // disabled={authBusy}
          />
          <div
            id="easyDataUsernameFeedback"
            className="bs4 invalid-feedback"
          >
            Required
          </div>
        </div>

        <div className="bs4 mb-3">
          <button
            className="bs4 btn btn-primary"
            type="button"
            onClick={handleSetHostname}
          >
            <span>Save</span>
          </button>
          {hostnameMsg &&
            <span className="bs4 ml-2">{hostnameMsg}</span>}
        </div>

        {hostnameError &&
          <div className="bs4 text-danger">{hostnameError}</div>}
      </form>
    </div>
  );
};

export default HostnameForm;
