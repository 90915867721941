import React from 'react';
import PropTypes from 'prop-types';

import usePrevious from '../hooks/usePrevious';

import logo from '../../media/easydata-logo.png';

const propTypes = {
  view: PropTypes.string.isRequired,
  onSetView: PropTypes.func.isRequired,
};

const Header = (props) => {
  const {
    view,
    onSetView,
  } = props;

  const previousView = usePrevious(view);

  function togglePreferences() {
    if (view === 'PREFERENCES') {
      // onSetView('APP');
      onSetView(previousView);
    } else {
      onSetView('PREFERENCES');
    }
  }

  return (
    <div className="bs4 d-flex py-2 my-2 border-bottom">
      <div className="bs4 flex-fill align-items-center">
        <img
          width="163"
          height="44"
          src={logo}
          alt="EasyData logo"
          title="EasyData logo"
        />
      </div>
      <div>
        <button
          className="bs4 btn btn-sm btn-light bg-white border-0 shadow-none rounded-0"
          type="button"
          onClick={togglePreferences}
        >
          <strong>
            {view === 'APP' && '···'}
            {view === 'SELECTIONS' && '···'}
            {view === 'PREFERENCES' && '×'}
          </strong>
        </button>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
