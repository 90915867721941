import * as t from './actionTypes';

const initialState = {
  hostname: '',
  authCredentials: {},
  fre: '',
  enableFormatting: true,
};

/*
{
  token: "xyz",
  user_id: 1,
  username: "username",
  email: "email@example.com",
  is_superuser: true,
  expiry: "Mon Jul 19 2021 16:30:43 GMT+0200 (South Africa Standard Time)",
}
*/

const app = (state = initialState, action = {}) => {
  switch (action.type) {
    case t.UPDATE_HOSTNAME:
      return {
        ...state,
        hostname: action.hostname,
      };
    case t.RESET_AUTHCREDENTIALS:
      return {
        ...state,
        authCredentials: {},
      };
    case t.UPDATE_AUTHCREDENTIALS:
      return {
        ...state,
        authCredentials: action.authCredentials,
      };
    case t.UPDATE_FRE:
      return {
        ...state,
        fre: action.fre,
      };
    case t.UPDATE_ENABLECOLORS:
      return {
        ...state,
        enableFormatting: action.enableFormatting,
      };
    default:
      return state;
  }
};

export default app;
