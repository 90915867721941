import { actions } from 'redux-scaffolding';

const {
  fetchElems,
  resetState,
} = actions;

const nameSpace = 'selections';
const apiPath = '/api/v3/selections/';

// FETCHING
/*
 * /selections/            => Fetch selections owned by and shared with this user
 */

export const fetchSelections = (queryParams) => {
  // Must get from localStorage everytime we fire action to ensure freshness
  const hostname = localStorage.getItem('hostname');
  const apiURL = `${hostname}${apiPath}`;
  return fetchElems(nameSpace, apiURL, { queryParams });
};

export const resetSelections = () => resetState(nameSpace);
