import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import stateSelections from '../state/selections';

import Loading from './Loading';
import Selection from './Selection';

const { fetchSelections } = stateSelections.actions;
const {
  getSelections,
  getFetchingSelectionsComplete,
} = stateSelections.selectors;

const Selections = () => {
  const dispatch = useDispatch();

  // app
  const authToken = useSelector((state) => state.app.authCredentials.token);

  // selections
  const selections = useSelector(getSelections);
  const fetchingSelectionsComplete = useSelector(getFetchingSelectionsComplete);

  const inactiveSelections = selections.filter((obj) => !obj.is_active);
  const activeSelection = selections.find((obj) => obj.is_active);

  const handleFetchSelections = () => {
    dispatch(fetchSelections({ auth_token: authToken }));
  };

  return (
    <div className="bs4 d-flex flex-column flex-fill overflow-hidden">

      {/*
      <div>
        TODO: Filter
      </div>
      <div>
        TODO: Sort
      </div>
      <div>
        TODO: Active selection first
      </div>
      */}

      {!fetchingSelectionsComplete &&
        <Loading />}

      {fetchingSelectionsComplete &&
        <div className="bs4 d-flex">
          <button
            className="bs4 btn btn-sm btn-primary flex-fill mb-2 shadow-none"
            type="button"
            onClick={handleFetchSelections}
          >
            <strong>
              Reload your selections
            </strong>
          </button>
        </div>}

      {fetchingSelectionsComplete &&
        <div
          className="bs4 mb-2"
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Selection
            index={0}
            selectionId={activeSelection.id}
          />
          {inactiveSelections.map((sel, i) =>
            <Selection
              index={i + 1}
              selectionId={sel.id}
              key={sel.id}
            />)}
        </div>}
    </div>
  );
};

export default Selections;
