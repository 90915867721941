import { createSelector } from 'reselect';

import { selectors } from 'redux-scaffolding';

const {
  getStateElems,
  getFetchingComplete,
  getElems,
} = selectors;

const nameSpace = 'selections';

const getStateSelections = (state) => getStateElems(nameSpace, state);

export const getFetchingSelectionsComplete = createSelector(
  [getStateSelections],
  (stateSelections) => getFetchingComplete(stateSelections),
);

export const getSelections = createSelector(
  [getStateSelections],
  (stateSelections) => {
    const elems = getElems(stateSelections);

    function getDataSetCodes(selection) {
      const dataSetCodes = selection.timeseriescodes.map((obj) => obj.split('-')[0]);
      const unique = [...new Set(dataSetCodes)].sort();
      return unique;
    }

    return elems.map((selection) => ({
      ...selection,
      route: `/selections/${selection.id}/`,
      dataSetCodes: getDataSetCodes(selection),
      dataSetCodesForFiltering: getDataSetCodes(selection).join(','),
      ownerFullName: `${selection.owner.first_name} ${selection.owner.last_name}`,
      uniqueKey: `s_${selection.id}`, // When we map over selections & recipes in a single array
    }));
  },
);
