import isToday from 'date-fns/isToday';

export const getLoggedInToday = (state) => {
  const { authCredentials } = state.app;
  if (authCredentials && authCredentials.expiry && authCredentials.token) {
    const expiry = Date.parse(authCredentials.expiry);
    return isToday(expiry);
  }
  return false;
};

export const getIsAdmin = (state) => {
  const { authCredentials } = state.app;
  if (authCredentials && authCredentials.is_superuser) {
    return true;
  }
  return false;
};
