import { useState } from 'react';

// Custom hook
function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setValue(e.target.type === 'checkbox' ? e.target.checked : e.target.value);
  }

  function handleError(errorMsg) {
    setError(errorMsg);
  }

  function handleSet(newValue) {
    setValue(newValue);
  }

  return {
    value,
    onChange: handleChange,
    error,
    onError: handleError,
    setValue: handleSet,
  };
}

export default useFormInput;
