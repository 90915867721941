import * as t from './actionTypes';

export const updateHostname = (hostname) => {
  // Every time we update hostname, also update in localStorage
  localStorage.setItem('hostname', hostname);
  return {
    type: t.UPDATE_HOSTNAME,
    hostname,
  };
};

export const resetAuthCredentials = () => {
  localStorage.removeItem('authCredentials');
  return {
    type: t.RESET_AUTHCREDENTIALS,
  };
};

/*
 * Update authCredential from localStorage value on reload/refresh of app. Update from
 * server response on successful log in attempt.
 */
export const updateAuthCredentials = (newAuthCredentials) => {
  let authCredentials = JSON.parse(localStorage.getItem('authCredentials')) || {};

  if (newAuthCredentials) {
    // A fresh log in, so replace with new auth credentials
    const now = new Date();
    // const now = Date(); // string
    authCredentials = {
      ...newAuthCredentials,
      expiry: now,
    };
    localStorage.setItem('authCredentials', JSON.stringify(authCredentials));
  }

  return {
    type: t.UPDATE_AUTHCREDENTIALS,
    authCredentials,
  };
};

/*
 * The very first time a user launches the Add-In task pane we show the "First Run Experience".
 */
export const updateFre = (fre) => {
  localStorage.setItem('fre', fre);
  return {
    type: t.UPDATE_FRE,
    fre,
  };
};

/*
 * Should Add-In update color formatting or not.
 */
export const updateEnableFormatting = (enableFormatting) => {
  // localStorage.setItem('enableFormatting', enableFormatting);
  // Serialize the boolean before storing it
  localStorage.setItem('enableFormatting', JSON.stringify(enableFormatting));
  return {
    type: t.UPDATE_ENABLECOLORS,
    enableFormatting,
  };
};
