import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// selections
import stateSelections from '../state/selections';

import {
  GREY_LIGHT,
  GREEN,
  GREEN_LIGHT,
} from '../constants';

const { getSelections } = stateSelections.selectors;

const propTypes = {
  index: PropTypes.number.isRequired,
  selectionId: PropTypes.number.isRequired,
};

const Selection = (props) => {
  const {
    index,
    selectionId,
  } = props;

  const hostname = localStorage.getItem('hostname');

  const selections = useSelector(getSelections);
  const selection = selections.find((obj) => obj.id === selectionId);

  const tsCount = selection.timeseriescodes.length;

  const handleHighlight = async () => {
    try {
      await Excel.run(async (context) => { // eslint-disable-line
        const topLeftRange = context.workbook.getSelectedRange();
        const metaRange = topLeftRange.getResizedRange(5, tsCount);
        const queryAnchorRange = topLeftRange.getOffsetRange(6, 0);
        let tsCodesRange = queryAnchorRange.getOffsetRange(0, 1);
        tsCodesRange = tsCodesRange.getResizedRange(0, tsCount - 1);

        // Read the selected range address. NB! Only one cell must be selected
        queryAnchorRange.load('address');
        tsCodesRange.load('address');

        // Insert the query keyword
        queryAnchorRange.format.fill.color = GREEN_LIGHT;
        queryAnchorRange.format.font.color = GREEN;
        queryAnchorRange.format.font.bold = true;
        queryAnchorRange.values = [['CODES:M']];

        // Insert the selection time series codes
        tsCodesRange.format.fill.color = GREEN_LIGHT;
        tsCodesRange.format.font.color = GREEN;
        tsCodesRange.format.font.bold = true;
        tsCodesRange.values = [selection.timeseriescodes];

        metaRange.format.fill.color = GREY_LIGHT;

        await context.sync(); // Method sends all queued commands to Excel for execution.
        console.log(`The range address was ${queryAnchorRange.address}, ${tsCodesRange.address}.`);
      });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div className="bs4 border-bottom py-1">
      <div
        className="bs4 d-flex align-items-center"
        key={selection.id}
      >
        <div className="bs4 flex-fill pr-1">
          <span className="bs4 mr-1">
            {`${index + 1}.`}
          </span>
          <a
            className="bs4 mr-1"
            href={`${hostname}/selections/${selection.id}/`}
          >
            {selection.title}
          </a>
          <span className="bs4 text-secondary mr-1">
            {`(${tsCount})`}
          </span>
          {selection.is_active &&
            <span className="badge badge-primary mr-1">
              ACTIVE
            </span>}
        </div>
        <div className="bs4 pl-1">
          <button
            className="bs4 btn btn-sm btn-primary mr-4 shadow-none"
            type="button"
            onClick={handleHighlight}
          >
            Insert
          </button>
        </div>
      </div>
      <div className="bs4 small text-secondary">
        {selection.description}
        <div>{selection.id}</div>
        {!selection.is_owner &&
          <div>{`Shared by ${selection.owner.username}`}</div>}
      </div>
    </div>
  );
};

Selection.propTypes = propTypes;

export default Selection;
