import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  browserName,
  // browserVersion,
  fullBrowserVersion,
  osName,
  osVersion,
} from 'react-device-detect';

import useFormInput from '../hooks/useFormInput';

import stateApp from '../state/app';

import HostnameForm from './HostnameForm';

const { updateAuthCredentials } = stateApp.actions;

const Login = () => {
  const dispatch = useDispatch();

  // app
  const hostname = useSelector((state) => state.app.hostname);

  // Authentication
  const [authBusy, setAuthBusy] = useState(false);
  const [authError, setAuthError] = useState(undefined);

  const authURL = `${hostname}/api/v3/authenticate/`;

  const username = useFormInput('');
  const password = useFormInput('');

  useEffect(
    () => {
      username.setValue('');
      password.setValue('');
    },
    [authError],
  );

  /*
  const proxyURL = 'https://cors-anywhere.herokuapp.com/';
  const authURL = `${proxyURL}https://${hostname}/api/v3/authenticate/`;
  */

  function handleAuthenticate() {
    setAuthError(undefined);
    setAuthBusy(true);

    fetch(authURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(`${username.value}:${password.value}`)}`, // encode string in base-64
      },
      // mode: 'no-cors',
    })
      .then((resp) => {
        // console.log(resp.ok);
        if (!resp.ok) { // same as (resp.status >= 200 || resp.status < 300)
          return resp.json()
            .then((json) => {
              throw new Error(json.detail);
            });
        }
        return resp;

        /*
        if (resp.status >= 200 || resp.status < 300) {
          return resp;
        }
        throw new Error(`Bad response from server (${resp.status})`);
        */
      })
      .then((resp) => resp.json())
      .then(
        (json) => {
          // console.log(json.token);
          setAuthBusy(false);
          if (json.token) {
            dispatch(updateAuthCredentials(json));
          }
        },
        (err) => {
          // console.log(err);
          setAuthBusy(false);
          setAuthError(err.message);
        },
      );
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleAuthenticate();
  }

  const changeHostname = username.value === 'secret';

  /* eslint-disable */
  return (
    <>
      {changeHostname &&
        <HostnameForm />}

      <p className="bs4 h3 my-3">Log in</p>

      <form onSubmit={handleSubmit}>
        <div className="bs4 my-3">
          <label htmlFor="easyDataUsername">
            Username / Email
          </label>
          <input
            id="easyDataUsername"
            className="bs4 form-control"
            type="text"
            placeholder="Username"
            value={username.value}
            onChange={username.onChange}
            aria-describedby="easyDataUsernameFeedback"
            required
            autoComplete="off"
            disabled={authBusy}
          />
          <div
            id="easyDataUsernameFeedback"
            className="bs4 invalid-feedback"
          >
            Required
          </div>
        </div>

        <div className="bs4 my-3">
          <label htmlFor="easyDataPassword">
            Password
          </label>
          <input
            id="easyDataPassword"
            className="bs4 form-control"
            type="password"
            placeholder="Password"
            value={password.value}
            onChange={password.onChange}
            aria-describedby="easyDataPasswordFeedback"
            required
            disabled={authBusy}
          />
          <div
            id="easyDataPasswordFeedback"
            className="bs4 invalid-feedback"
          >
            Required
          </div>
        </div>

        <div className="bs4 my-4">
          <button
            className="bs4 btn btn-primary"
            type="submit"
            disabled={authBusy}
          >
            {!authBusy &&
              <span>Log in</span>}
            {authBusy &&
              <em>Busy…</em>}
          </button>
        </div>

        {authError &&
          <div className="bs4 text-danger">{authError}</div>}
      </form>

      <p className="bs4 small my-3">
        <a
          href="https://www.easydata.co.za/accounts/password/reset/"
          target="_blank"
        >
          Forgot your password?
        </a>
      </p>
      <p className="bs4 small my-3">
        Don&rsquo;t have an EasyData account yet?
        <a
          className="bs4 ml-2"
          href="https://www.easydata.co.za/accounts/register/"
          target="_blank"
        >
          Sign up Here
        </a>
      </p>
      <p className="bs5 text-muted">
        <small>
          {`System: ${osName} ${osVersion}, Browser: ${browserName} ${fullBrowserVersion}`}
        </small>
      </p>
    </>
  );
};

export default Login;
