import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import stateApp from '../state/app';
import stateSelections from '../state/selections';

import useFormInput from '../hooks/useFormInput';

const { resetAuthCredentials } = stateApp.actions;
const { resetSelections } = stateSelections.actions;
const { updateEnableFormatting } = stateApp.actions;

const Preferences = () => {
  const dispatch = useDispatch();

  const hostname = useSelector((state) => state.app.hostname);
  const authCredentials = useSelector((state) => state.app.authCredentials);
  const enableFormatting = useSelector((state) => state.app.enableFormatting);
  const { username } = authCredentials;

  const enableFormattingForm = useFormInput(enableFormatting);

  useEffect(
    () => {
      dispatch(updateEnableFormatting(enableFormattingForm.value));
    },
    [enableFormattingForm.value],
  );

  function handleLogOut() {
    dispatch(resetAuthCredentials({}));
    dispatch(resetSelections({}));
  }

  return (
    <div>
      <div className="bs4 my-3">
        <span className="bs4 mr-1">Logged in as:</span>
        <strong>{username}</strong>
      </div>

      <div className="bs4 my-3">
        <span className="bs4 mr-1">Connected to:</span>
        <strong>{hostname}</strong>
      </div>

      <div className="bs4 my-3">
        <span className="bs4 mr-1">Version:</span>
        <strong>2024.02.29</strong>
      </div>

      <div className="form-group form-check my-3">
        <label
          htmlFor="enable-formatting"
          className="form-check-label"
        >
          <input
            id="enable-formatting"
            type="checkbox"
            className="form-check-input"
            checked={enableFormattingForm.value}
            onChange={enableFormattingForm.onChange}
          />
          Use EasyData formatting
        </label>
      </div>

      <hr />

      <div className="bs4 small my-3">
        <a href="https://www.quantec.co.za/easydata/api-tools/excel-addin/">Help</a>
      </div>

      <div>
        <button
          className="bs4 btn btn-sm btn-link border-0 shadow-none rounded-0 p-0"
          type="button"
          onClick={handleLogOut}
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default Preferences;
